<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 py-5">
            <div class="card-title">
              <h3 class="card-label">{{ $t('ARTICLES.ARTICLES') }}</h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body py-5">
            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center">
                <thead>
                  <tr class="text-left text-uppercase">
                    <th class="pl-0">{{ $t('ARTICLES.ARTICLE_NAME') }}</th>
                    <th class="text-right">{{ $t('GENERAL.AMOUNT') }}</th>
                    <th class="text-right">{{ $t('ARTICLES.NET_PRICE') }}</th>
                    <th class="text-right">{{ $t('ARTICLES.GROSS_PRICE') }}</th>
                    <th class="text-right">{{ $t('GENERAL.VAT') }}</th>
                    <th class="text-right">{{ $t('ARTICLES.SUPPLIER') }}</th>
                    <th class="text-right">{{ $t('GENERAL.OPTIONS') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="article in articles"
                    :key="article.id"
                    class="animate__animated animate__fadeIn"
                  >
                    <td class="pl-0">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ article.name }}</span
                      >
                    </td>
                    <td class="text-right">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ article.quantity }} {{ article.unit }}</span
                      >
                    </td>
                    <td class="text-right">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        <span>{{ $t('GENERAL.PURCHASE') }}: </span>
                        <span>{{ article.netPurchasePrice }} €</span>
                      </span>
                      <span class="text-muted font-weight-bold">
                        <span>{{ $t('GENERAL.SALE') }}: </span>
                        <span>{{ article.netSalesPrice }} €</span>
                      </span>
                    </td>
                    <td class="text-right">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        <span>{{ $t('GENERAL.PURCHASE') }}: </span>
                        <span>{{ article.grossPurchasePrice }} €</span>
                      </span>
                      <span class="text-muted font-weight-bold">
                        <span>{{ $t('GENERAL.SALE') }}: </span>
                        <span> {{ article.grossSalesPrice }} €</span>
                      </span>
                    </td>
                    <td class="text-right">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ article.pdv }} %</span
                      >
                    </td>
                    <td class="text-right">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ article.supplier }}</span
                      >
                    </td>
                    <td class="text-right pr-0">
                      <button
                        @click="toggleArticleQuantityModal(article.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Navigation/Plus.svg"
                          />
                        </span>
                      </button>
                      <button
                        @click="toggleRepositoryArticleModal(article.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/Code/Git3.svg" />
                        </span>
                      </button>
                      <router-link
                        :to="'/articles/items/edit/' + article.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="repository_modal" centered hide-header hide-footer>
          <div>
            <div class="row">
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('repository_modal')"
                >{{ $t('GENERAL.CLOSE') }}</b-button
              >
              <div class="col-12">
                <div class="form-group">
                  <label class="font-weight-bolder"
                    >{{ $t('ARTICLES.REPOSITORIES') }}*</label
                  >
                  <vSelect
                    v-model="tempRepository"
                    class="form-control form-control--vt-select"
                    :placeholder="$t('ARTICLES.REPOSITORY')"
                    :options="repositories"
                    :reduce="(repository) => repository['@id']"
                    label="name"
                  >
                    <template #option="{ name }">
                      <li>{{ name }}</li>
                    </template>
                  </vSelect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="font-weight-bolder"
                    >{{ $t('ARTICLES.QUANTITY') }}*</label
                  >
                  <input
                    v-model="tempQuantity"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-0">
                  <button
                    @click="addArticle"
                    class="btn btn-light-primary btn-block"
                  >
                    {{ $t('GENERAL.ADD') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal id="article_quantity_modal" centered hide-header hide-footer>
          <div>
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('article_quantity_modal')"
              >{{ $t('GENERAL.CLOSE') }}</b-button
            >
            <div class="form-group">
              <input
                v-model="currentQuantity"
                type="number"
                class="form-control form-control-solid"
                :placeholder="$t('ARTICLES.QUANTITY')"
              />
            </div>
            <div class="form-group">
              <button @click="addQuantity" class="btn btn-primary btn-block">
                {{ $t('GENERAL.ADD') }}
              </button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'

export default {
  name: 'ArticlesList',
  components: {
    vSelect,
  },
  data() {
    return {
      articles: [],
      currentArticle: null,
      currentQuantity: 0,
      totalItems: null,
      devices: [],
      repositories: [],
      tempQuantity: 0,
      tempRepository: null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Artikli', route: '' }])

    ApiService.get('articles').then(({ data }) => {
      this.totalItems = data['hydra:member'].length
      this.articles = [...data['hydra:member']]
    })

    ApiService.get('article_stores').then(({ data }) => {
      this.repositories = [...data['hydra:member']]
    })
  },
  methods: {
    toggleArticleQuantityModal(id) {
      this.currentArticle = this.articles.find((article) => article.id === id)

      this.$root.$emit('bv::show::modal', 'article_quantity_modal')
    },
    addQuantity() {
      ApiService.update('articles', this.currentArticle.id, {
        quantity:
          parseFloat(this.currentQuantity) +
          parseFloat(this.currentArticle.quantity) +
          '',
      }).then(() => {
        ApiService.get('articles').then(({ data }) => {
          this.articles = []
          this.articles = [...data['hydra:member']]
        })
      })

      this.currentQuantity = 0
      this.$root.$emit('bv::hide::modal', 'article_quantity_modal')
    },

    toggleRepositoryArticleModal(id) {
      this.currentArticle = this.articles.find((article) => article.id === id)

      this.$root.$emit('bv::show::modal', 'repository_modal')
    },
    addArticle() {
      ApiService.get(this.tempRepository.replace('/api/v1', '')).then(() => {})

      this.$root.$emit('bv::hide::modal', 'repository_modal')
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--vt-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
